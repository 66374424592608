/* eslint-disable max-lines */
import { Injectable } from "@angular/core";
import
{
  APIService,
  unwrapResponse
} from "./api.service";

import
{
  ErrorHandlerCreator,
  ResponseErrorHandler
} from "./response-error-handler.service";
import
{
  Observable, catchError
} from "rxjs";
import
{
  Institution,
  PartialData
} from "../models";
import { HttpParams } from "@angular/common/http";
import { InstitutionSearch } from "../models/institution-search";

@Injectable({
  providedIn: "root"
})
export class InstitutionService
{
  static readonly url = "Institution";

  private handleError: ErrorHandlerCreator;

  constructor(protected readonly api: APIService,
    readonly responseErrorHandler: ResponseErrorHandler
  )
  {
    this.handleError = responseErrorHandler.createErrorHandler("Institution");
  }

  getInstitutions(skip: number, take: number)
    : Observable<PartialData<Institution> | null>
  {
    return this.api.get<PartialData<Institution>>(
      `${InstitutionService.url}?skip=${skip}&take=${take}`
    ).pipe(
      unwrapResponse(),
      catchError(this.handleError("getInstitution", null))
    );
  }

  SearchInstitution(institutionSearch: InstitutionSearch,
    skip: number, take: number)
    : Observable<PartialData<Institution> | null>
  {
    let params = new HttpParams();

    params = params.set("skip", skip).set("take", take);

    if (institutionSearch)
    {
      for (const key of Object.keys(institutionSearch))
      {
        params = params.set(key,
          institutionSearch[key as keyof InstitutionSearch]);
      }
    }

    const options = { params: params };

    return this.api.get<PartialData<Institution>>(
      `${InstitutionService.url}/search`, options
    ).pipe(
      unwrapResponse(),
      catchError(this.handleError("SearchInstitution", null))
    );
  }

}